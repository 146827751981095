exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-methods-js": () => import("./../../../src/pages/all-methods.js" /* webpackChunkName: "component---src-pages-all-methods-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offline-js": () => import("./../../../src/pages/offline.js" /* webpackChunkName: "component---src-pages-offline-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-discipline-js": () => import("./../../../src/templates/discipline.js" /* webpackChunkName: "component---src-templates-discipline-js" */),
  "component---src-templates-method-js": () => import("./../../../src/templates/method.js" /* webpackChunkName: "component---src-templates-method-js" */)
}

